import React from 'react'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = (props) => {

  const [state, setState] = React.useState({})
  const [submited, setSubmited] = React.useState(false)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
     e.preventDefault()
     const form = e.target
     fetch('/', {
       method: 'POST',
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       body: encode({
         'form-name': form.getAttribute('name'),
         ...state,
       }),
     })
       .then(() => setSubmited(true))
       .catch((error) => alert(error))
   }

  return(
    <section id="contact">
        <div className="inner">
            <section>
              {!submited ?
                <form method="post"
                  name="contact"
                  action="/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}>
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" onChange={handleChange}/>
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" onChange={handleChange}/>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6" onChange={handleChange}></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear"/></li>
                    </ul>
                </form>
              :
              <>
                <header className="major">
                  <h2>Sent!</h2>
                </header>
                <p>
                Thank you for contacting Wild Poppy Photo. I am excited to work with you and will be returning your inquiry shortly!
                </p>
              </>}
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:lisa.hansen9@gmail.com">lisa.hansen9@gmail.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-instagram"></span>
                        <h3>Instagram</h3>
                        <a href="https://www.instagram.com/lisahansen_photography/" rel="noreferrer" target="_blank">@lisahansen_photography</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Location</h3>
                        <span>
                        Ketchum, ID 83340<br />
                        United States of America</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)}

export default Contact
