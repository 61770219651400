import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="#about">About</Link></li>
                <li><a onClick={props.onToggleMenu} target="_blank" rel="noreferrer" href="https://wildpoppyphoto.pic-time.com/client">Portfolio</a></li>
                <li><a onClick={props.onToggleMenu} target="_blank" rel="noreferrer" href="https://wildpoppyphoto.pic-time.com/-reedmorgan/sneakpeek">Wedding</a></li>
                <li><a onClick={props.onToggleMenu} target="_blank" rel="noreferrer" href="https://wildpoppyphoto.pic-time.com/-nina/sneakpeek">Engagement</a></li>
                <li><a onClick={props.onToggleMenu} target="_blank" rel="noreferrer" href="https://wildpoppyphoto.pic-time.com/-alytobymaternity/sneakpeek">Maternity</a></li>
                <li><a onClick={props.onToggleMenu} target="_blank" rel="noreferrer" href="https://wildpoppyphoto.pic-time.com/-brownfallphotos/sneakpeek">Family</a></li>
            </ul>
          {
            // <ul className="actions vertical">
            //     <li><a href="#" className="button special fit">Get Started</a></li>
            //     <li><a href="#" className="button fit">Log In</a></li>
            // </ul>
          }
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
