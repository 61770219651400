import React from 'react'

const Footer = props => {

  const d = new Date();
  const year = d.getFullYear();

  return(
      <footer id="footer">
        <div className="inner">
          <ul className="copyright">
            <li>&copy; {year}</li>
            <li>Lisa Hansen</li>
          </ul>
        </div>
      </footer>
    )
}

export default Footer
